import { FC } from 'react';
import Box from '@material-ui/core/Box';
import { Rating } from '@material-ui/lab';
import Image from 'next/image';

interface ReviewCardProps {
  src: string;
  name: string;
  job: string;
  time: string;
  desc: string;
}

const ReviewCard: FC<ReviewCardProps> = ({ src, name, job, time, desc }) => (
  <div className="c-review-card">
    <Box display="flex" pb={{ xs: 9, md: 14 }} className="u-border-bottom">
      <Box width={{ xs: 48, md: 80 }} height={{ xs: 48, md: 80 }} mr={{ xs: 4, md: 12 }} position="relative">
        <Image
          src={src}
          alt={name}
          blurDataURL={`/_next/image?url=${src}&w=16&q=1`}
          placeholder="blur"
          width={80}
          height={80}
        />
      </Box>
      <Box flex={1}>
        <h4 className="c-review-card__name">{name}</h4>
        <Box mt={{ xs: 3, md: 6 }} mb={{ xs: 4, md: 9 }} fontSize={{ xs: 8, md: 14 }} color="#757575">
          {job}
        </Box>
        <div className="c-review-card__rating">
          <Rating name={name} defaultValue={5} size="small" disabled />
          <Box color="#757575">{time}</Box>
        </div>
      </Box>
    </Box>
    <Box mt={{ xs: 8, md: 10 }} color="#484545" fontSize={{ xs: 10, md: 16 }} lineHeight={{ xs: 1.5, md: 1.8 }}>
      {desc}
    </Box>
  </div>
);

export default ReviewCard;
