import { FC } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const OBoostYourChangeSection: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  return (
    <Box pb={{ xs: 20, md: 40 }}>
      <Container>
        <Box textAlign="center" bgcolor="#d9e8ff" color="#484545" borderRadius={20}>
          <Box pt={40} pb={40} mr="auto" ml="auto" maxWidth={560}>
            <h2 className="o-heading1">{t('oBoostYourChangeSection.boostYourChance')}</h2>
            <Box mt={17} mb={22} className="o-body-regular1">
              {t('oBoostYourChangeSection.dontWait')}.
            </Box>
            <Button
              variant="contained"
              color="secondary"
              className="c-primary-button c-primary-button--orange"
              onClick={() => router.push(isLoggedIn ? '/my-cvs' : '/register')}
            >
              {t('oBoostYourChangeSection.createYourCv')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OBoostYourChangeSection;
