import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaTwitter } from 'react-icons/fa';
import useTranslation from 'next-translate/useTranslation';
import ALink from 'components/atoms/ALink';

const socialList = [
  { title: 'Facebook', icon: <FaFacebook size={18} />, path: 'https://www.facebook.com/urbancv' },
  { title: 'Instagram', icon: <FaInstagram size={18} />, path: 'https://www.instagram.com/urbancv.official/' },
  { title: 'LinkedIn', icon: <FaLinkedin size={18} />, path: 'https://www.linkedin.com/company/urban-cv/' },
  { title: 'Pinterest', icon: <FaPinterest size={18} />, path: 'https://www.pinterest.com/urbancvofficial/' },
  { title: 'Twitter', icon: <FaTwitter size={18} />, path: 'https://twitter.com/urbancv/' },
];

const Footer = () => {
  const { t } = useTranslation('common');
  const navLinks = [
    { title: t('footer.about'), path: '/about' },
    { title: t('footer.contact'), path: '/contact-us' },
    { title: t('footer.termsAndServices'), path: '/terms-and-conditions' },
    { title: t('footer.privacyPolicy'), path: '/privacy-policy' },
  ];
  const year = new Date().getFullYear();
  return (
    <div id="footer">
      <Container>
        <Box className="footer-content" py={{ xs: 12, md: 0 }}>
          <Hidden smDown>
            <Box fontSize={12}>{t('footer.copyright', { year })}</Box>
          </Hidden>
          <List component="nav" id="footer-nav">
            {navLinks.map(({ title, path }) => (
              <ALink href={path} key={title}>
                <ListItem button>
                  <ListItemText primary={title} />
                </ListItem>
              </ALink>
            ))}
          </List>
          <Box py={{ xs: 12, md: 0 }}>
            <List component="nav" id="footer-socials">
              {socialList.map(({ title, icon, path }) => (
                <ALink href={path} key={title}>
                  <Box component="span" className="u-pointer" ml={6} p={6}>
                    {icon}
                  </Box>
                </ALink>
              ))}
            </List>
          </Box>
          <Hidden mdUp>
            <Box fontSize={12}>{t('footer.copyright', { year })}</Box>
          </Hidden>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
