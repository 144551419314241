import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import useTranslation from 'next-translate/useTranslation';
import MReviewCard from 'components/molecules/MReviewCard';

const OExcellentSection = () => {
  const { t } = useTranslation('common');

  return (
    <Box mt={{ xs: 0, md: 60 }} py={{ xs: 20, md: 32 }} className="home-excellent">
      <Container className="home-excellent-content">
        <Box className="home-excellent-title">
          <Box>{t('common.excellent')}</Box>
          <Box className="home-excellent__desc">{t('common.masterful')}</Box>
        </Box>

        <Box
          width={{ xs: '100%', md: 450 }}
          height={{ xs: 190, md: 370 }}
          mt={{ xs: 12, md: 0 }}
          position={{ xs: 'unset', md: 'absolute' }}
          top={-100}
          left={{ md: 30, lg: 300 }}
        >
          <MReviewCard
            src="/assets/padela_timeg.png"
            name="Padela Timeg"
            job={t('common.jobSeeker')}
            time={`1 ${t('common.dayAgo')}`}
            desc={t('common.looksGreat')}
          />
        </Box>

        <Box
          width={{ xs: '100%', md: 450 }}
          height={{ xs: 190, md: 370 }}
          mt={{ xs: 12, md: 0 }}
          position={{ xs: 'unset', md: 'absolute' }}
          top={-100}
          left={{ md: 520, lg: 330 + 445 }}
        >
          <MReviewCard
            src="/assets/nino_agustin.png"
            name="Nino Agustin"
            job={t('common.jobSeeker')}
            time={`1 ${t('common.dayAgo')}`}
            desc={t('common.thankYouForTemplate')}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default OExcellentSection;
