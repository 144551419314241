import Box from '@material-ui/core/Box';
import Image from 'next/image';

export default function MPartnerLogos() {
  return (
    <Box display="flex" alignItems="center">
      <Box mt={10} width={135} height={100} position="relative">
        <Image
          src="/assets/logo_9cv9.png"
          alt="9cv9"
          blurDataURL="/_next/image?url=/assets/logo_9cv9.png&w=16&q=1"
          placeholder="blur"
          width={240}
          height={120}
        />
      </Box>
      <Box mt={10} width={135} height={100} position="relative">
        <Image
          src="/assets/logo_jobs_refer.png"
          alt="Jobrefer"
          blurDataURL="/_next/image?url=/assets/logo_jobs_refer.png&w=16&q=1"
          placeholder="blur"
          width={240}
          height={120}
        />
      </Box>
    </Box>
  );
}
